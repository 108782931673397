<template>
	<div>
		<div class="front-social-medias left" :class="{ 'sticky-bottom': isSticky }" v-if="leftSocialIcons.length > 0">
			<div v-for="(social, index) in leftSocialIcons" :key="`social-data-icon${index}`" class="my-2">
				<v-btn
					small
					:color="social.background"
					fab
					:href="social.icon.indexOf('whatsapp') > -1 ? `https://wa.me/${social.link}` : social.link"
					target="_blank"
				>
					<v-icon small>{{ social.icon }}</v-icon>
				</v-btn>
			</div>
		</div>
		<div class="front-social-medias right" :class="{ 'sticky-bottom': isSticky }" v-if="rightSocialIcons.length > 0">
			<div v-for="(social, index) in rightSocialIcons" :key="`social-data-icon${index}`" class="my-2">
				<v-btn
					small
					:color="social.background"
					fab
					:href="social.icon.indexOf('whatsapp') > -1 ? `https://wa.me/${social.link}` : social.link"
					target="_blank"
				>
					<v-icon small>{{ social.icon }}</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FrontSocialMediaIcons',
	props: {
		socialData: {
			required: false,
			default: () => null,
		},
	},
	data() {
		return {
			stickyBottom: false,
		};
	},
	computed: {
		isSticky() {
			return this.stickyBottom;
		},
		leftSocialIcons() {
			return this.socialData.filter((x) => x.location === 2);
		},
		rightSocialIcons() {
			return this.socialData.filter((x) => x.location === 1);
		},
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 50) {
				this.stickyBottom = true;
			} else {
				this.stickyBottom = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.front-social-medias {
	position: fixed;
	bottom: 10px;
	transition: bottom 0.2s ease-in-out;
	&.sticky-bottom {
		bottom: 155px;
	}

	a {
		height: 50px !important;
		width: 50px !important;

		i {
			font-size: 30px !important;
			color: #fff !important;
		}
	}
}

.left {
	left: 25px;
}

.right {
	right: 25px;
}
</style>
