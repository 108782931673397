<template>
	<v-footer class="foooter-bg" height="134" max-height="150">
		<front-social-media-icons :social-data="socialData"></front-social-media-icons>
		<v-container fluid>
			<v-row dense class="d-flex align-center transparent">
				<v-col cols="4" class="d-flex align-center">
					<v-img
						class="bottom-logo"
						:src="getSite.companyLogo"
						:max-height="logoSize.height"
						:max-width="logoSize.width"
						aspect-ratio="2.1"
						contain
					></v-img>
				</v-col>
				<v-col cols="8 " :class="{ 'd-flex justify-end align-center': !getSite.footer }">
					<social-network></social-network>
					<v-row dense v-if="getSite.footer" class="mt-1">
						<v-col md="5" offset-md="7" cols="12" class="footer-text text-right">
							{{ getSite.footer }}
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</v-footer>
</template>

<script>
import { mapGetters } from 'vuex';
import SocialNetwork from '@/components/enduser/footer/SocialNetwork.vue';
import FrontSocialMediaIcons from '@/components/enduser/footer/FrontSocialMediaIcons.vue';

export default {
	components: { SocialNetwork, FrontSocialMediaIcons },
	name: 'FooterBar',
	data() {
		return {
			
		};
	},
	computed: {
		...mapGetters(['getSite']),
		logoSize() {
			return this.$vuetify.breakpoint.smAndDown
				? {
						height: '80px',
						width: '150px',
				  }
				: {
						height: '100px',
						width: '180px',
				  };
		},
		socialData() {
			return this.getSite.socialMedia.filter((x)=>x.isFeatured)
		}
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.bottom-logo {
		.v-image__image {
			background-position: left center !important;
		}
	}
}

.foooter-bg {
	position: relative;
}

.theme--dark {
	&.foooter-bg {
		background-color: var(--v-footer-color-base);
	}
	.footer-text {
		background-color: var(--v-footer-color-base) !important;
		color: var(--v-footer-text-base);
		line-height: 14px !important;
	}
}
.theme--light {
	&.foooter-bg {
		background-color: var(--v-footer-color-base);
	}
	.footer-text {
		background-color: var(--v-footer-color-base) !important;
		color: var(--v-footer-text-base);
		line-height: 14px !important;
	}
}
</style>
