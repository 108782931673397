<template>
	<div class="d-flex align-end justify-end" v-if="getSite.socialMedia.length > 0">
		<v-btn
			v-for="social in getSite.socialMedia"
			:key="social.icon"
			:href="social.icon.indexOf('whatsapp') > -1 ? `https://wa.me/${social.link}` : social.link"
			:fab="fab"
			target="_blank"
			class="social-icon mx-2"
			depressed
			x-small
			:width="size"
			:height="size"
			min-width="0"
			min-height="0"
		>
			<v-icon :size="iconSize" v-text="social.icon"> </v-icon>
		</v-btn>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'SocialNetwork',
	computed: {
		...mapGetters(['getSite']),
		fab() {
			return this.getSite.templateCode === 'dark-one';
		},
		size() {
			return this.$vuetify.breakpoint.smAndDown ? '26px' : '32px';
		},
		iconSize() {
			return this.$vuetify.breakpoint.smAndDown ? '13px' : '16px';
		},
	},
};
</script>

<style lang="scss" scoped>
.social-icon {
	background-color: var(--v-footer-text-base) !important;
	.v-icon {
		color: var(--v-template-color-darken1);
	}
}
</style>
