<template>
	<v-app-bar
		:style="style"
		flat
		tile
		app
		:height="this.$vuetify.breakpoint.smAndDown ? this.appBarSizes.mobileHeight : this.appBarSizes.desktopHeight"
		:scroll-threshold="
			this.$vuetify.breakpoint.smAndDown ? this.appBarSizes.mobileHeight : this.appBarSizes.desktopHeight
		"
		:max-height="this.appBarSizes.maxHeight"
		:class="headerClass"
		:dark="isDark"
		:elevation="elevation"
		:fixed="true"
	>
		<div class="quick-bar" v-if="isTopBarShow && !$vuetify.breakpoint.mobile">
			<v-container class="py-0 fill-height">
				<div class="d-flex flex-row justify-space-between phone">
					<div class="d-flex flex-row align-center">
						<div class="mr-5" v-if="getSite.email != null && getSite.email != ''">
							<v-icon x-small left class="quick-bar-icon">fas fa-envelope</v-icon>
							<a :href="`mailto:${getSite.email}`" class="contact-info">
								{{ getSite.email }}
							</a>
						</div>
						<div class="justify-content-start" v-if="getSite.workHours.length > 0">
							<v-icon x-small left class="quick-bar-icon">fas fa-clock</v-icon>
							{{ getSite.workHours[0].day }} {{ getSite.workHours[0].hours }}
						</div>
					</div>
					<div class="d-flex flex-row align-center">
						<div v-if="getSite.phone != null && getSite.phone != ''">
							<a :href="`tel:${getSite.phone}`" class="text-capitalize font-weight-bold mx-4">
								<v-icon x-small left class="quick-bar-icon">fas fa-phone</v-icon>
								{{ getSite.phone | VMask(phoneMask) }}</a
							>
						</div>
						<div class="d-flex" v-if="getSite.socialMedia.length > 0">
							<v-btn
								v-for="social in getSite.socialMedia"
								:key="social.icon"
								:href="social.icon.indexOf('whatsapp') > -1 ? `https://wa.me/${social.link}` : social.link"
								target="_blank"
								x-small
								icon
								text
								class="ml-2"
							>
								<v-icon :size="14">{{ social.icon }} </v-icon>
							</v-btn>
						</div>
					</div>
				</div>
			</v-container>
		</div>
		<div class="quick-bar" v-if="isTopBarShow && $vuetify.breakpoint.mobile">
			<v-container class="py-0 fill-height">
				<div class="d-flex flex-row justify-space-between phone">
					<div class="d-flex flex-row align-center">
						<v-menu absolute close-on-click content-class="mobile-quick-bar" right left max-width="100%">
							<template v-slot:activator="{ on }">
								<v-btn dark icon x-small v-on="on" class="hidden-lg-and-up">
									<v-icon x-small>fas fa-angle-double-down</v-icon>
								</v-btn>
							</template>
							<v-card tile :key="`mobile-quick-bar`">
								<v-card-text class="mobile-quick-bar phone">
									<div class="d-flex flex-row align-center">
										<div class="mr-5" v-if="getSite.email != null && getSite.email != ''">
											<v-icon x-small left class="quick-bar-icon">fas fa-envelope</v-icon>
											<a :href="`mailto:${getSite.email}`" class="contact-info">
												{{ getSite.email }}
											</a>
										</div>
										<div class="justify-content-start" v-if="getSite.workHours.length > 0">
											<v-icon x-small left class="quick-bar-icon">fas fa-clock</v-icon>
											{{ getSite.workHours[0].day }} {{ getSite.workHours[0].hours }}
										</div>
									</div>
								</v-card-text>
							</v-card>
						</v-menu>
					</div>
					<div class="d-flex flex-row align-center">
						<div v-if="getSite.phone != null && getSite.phone != ''">
							<a :href="`tel:${getSite.phone}`" class="text-capitalize font-weight-bold mx-4">
								<v-icon x-small left class="quick-bar-icon">fas fa-phone</v-icon>
								{{ getSite.phone | VMask(phoneMask) }}</a
							>
						</div>
						<div class="d-flex" v-if="getSite.socialMedia.length > 0">
							<v-btn
								v-for="social in getSite.socialMedia"
								:key="social.icon"
								:href="social.icon.indexOf('whatsapp') > -1 ? `https://wa.me/${social.link}` : social.link"
								target="_blank"
								x-small
								icon
								text
								class="ml-2"
							>
								<v-icon :size="14"> {{ social.icon }}</v-icon>
							</v-btn>
						</div>
					</div>
				</div>
			</v-container>
		</div>
		<v-container class="py-0">
			<div class="d-flex align-center">
				<v-img
					@click="goToHome"
					class="top-logo"
					:lazy-src="getSite.companyLogo"
					:src="getSite.companyLogo"
					:max-height="logoSize.height"
					:max-width="logoSize.width"
					contain
					aspect-ratio="2"
				></v-img>
				<v-spacer></v-spacer>

				<v-toolbar-items class="hidden-md-and-down">
					<template v-for="item in menuItems">
						<router-link
							class="text-capitalize font-weight-bold mx-4"
							tag="a"
							:to="{ name: item.name }"
							:key="`desktop-menu-${item.title}`"
							v-if="item.show"
						>
							{{ item.title }}
						</router-link></template
					>
				</v-toolbar-items>

				<v-menu
					allow-overflow
					absolute
					close-on-click
					attach=".v-toolbar__content"
					content-class="mobile-menu rounded-0"
					right
					left
					max-width="100%"
				>
					<template v-slot:activator="{ on }">
						<v-btn dark icon v-on="on" class="hidden-lg-and-up">
							<v-icon class="mobile-icon">fa fa-bars</v-icon>
						</v-btn>
					</template>
					<v-card tile>
						<v-list dense nav light>
							<template v-for="item in menuItems">
								<v-list-item :to="{ name: item.name }" :key="`mobile-menu-${item.title}`" v-if="item.show">
									<v-list-item-title> {{ item.title }}</v-list-item-title>
								</v-list-item>
							</template>
						</v-list>
					</v-card>
				</v-menu>
			</div>
		</v-container>
	</v-app-bar>
</template>

<script>
import { wizardStep } from '@/common/enums';
import { mapGetters } from 'vuex';

export default {
	name: 'TopBar',
	components: {},
	data() {
		return {
			isScrolled: false,
			wizardStep,
		};
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	computed: {
		...mapGetters(['getSite', 'getWizardStep', 'hasListing', 'isListingApp', 'showListingCarMenu']),
		menuItems() {
			return [
				{ name: 'home', title: 'Ana sayfa', show: true },
				...(this.showListingCarMenu ? [{ name: 'cars', title: 'Araçlarımız', show: this.showListingCarMenu }] : []),
				{ name: 'why-us', title: 'Neden biz', show: this.isWhyUseShow },
				{ name: 'faq', title: 'Sıkça sorulan sorular', show: this.isFaqShow },
				{ name: 'contact', title: 'İletişim', show: true },
			];
		},
		isDark() {
			return this.companyHasListing ? false : this.$vuetify.breakpoint.smAndDown;
		},
		headerIsAbsolute() {
			return this.getWizardStep === wizardStep.MODEL;
		},
		isFaqShow() {
			return this.getSite?.faq.length > 0;
		},
		isWhyUseShow() {
			return this.getSite?.businessStep.length > 0;
		},
		elevation() {
			return this.headerIsAbsolute ? 0 : 4;
		},
		style() {
			return {
				'--v-toolbar--content': this.isTopBarShow ? '35px' : '0px',
			};
		},
		headerClass() {
			return [
				{
					'v-app-bar--is-scrolled': this.$vuetify.breakpoint.smAndDown,
					'wizard--next': !this.headerIsAbsolute,
					'listing--platform': this.companyHasListing,
				},
			];
		},
		companyHasListing() {
			return this.hasListing && this.isListingApp;
		},
		logoSize() {
			return this.$vuetify.breakpoint.smAndDown
				? {
						height: '85px',
						width: '150px',
				  }
				: {
						height: '120px',
						width: '180px',
				  };
		},
		isTopBarShow() {
			return this.getSite?.showContactInfoHeader;
		},
		appBarSizes() {
			return this.isTopBarShow
				? {
						mobileHeight: '110px',
						desktopHeight: '120px',
						maxHeight: '120px',
				  }
				: {
						mobileHeight: '85px',
						desktopHeight: '95px',
						maxHeight: '100px',
				  };
		},
		phoneMask() {
			if (this.getSite?.phone.startsWith('444')) return '### # ###';

			return '0 (###) ### ## ##';
		},
	},
	methods: {
		handleScroll() {
			this.isScrolled = window.scrollY > 10;
		},
		scrollToTopAnimated() {
			const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

			if (currentScroll > 0) {
				window.requestAnimationFrame(this.scrollToTopAnimated.bind(this));
				window.scrollTo(0, currentScroll - currentScroll / 10);
			}
		},
		scrollToTop() {
			if (this.isScrolled) {
				this.scrollToTopAnimated();
			}
		},
		goToHome() {
			if (this.$route.path !== '/') {
				this.$router.push({ name: 'home' });
			} else {
				this.scrollToTop();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
a {
	text-decoration: none;
}

.mobile-menu {
	left: 0px !important;
	top: 85px !important;
	right: 0px !important;
}

::v-deep {
	.top-logo {
		cursor: pointer;
		.v-image__image {
			background-position: left center !important;
		}
	}

	.v-toolbar {
		&__content {
			padding-top: var(--v-toolbar--content) !important;
		}
	}
}

.mobile-icon {
	color: #4e4e4e !important;
}
.v-app-bar {
	background-color: transparent !important;
	&.theme--dark {
		background-color: var(--v-header-color-base) !important;
		a {
			color: var(--v-header-text-base);
		}
	}

	a {
		color: #333;
	}

	&--is-scrolled {
		background-color: var(--v-header-color-base) !important;
		a {
			color: var(--v-header-text-base) !important;
		}

		.v-menu__content {
			a {
				color: #333 !important;
			}
		}

		.mobile-icon {
			color: var(--v-header-text-base) !important;
		}
	}

	&.listing--platform {
		background-color: var(--v-header-color-base) !important;
		a {
			color: var(--v-header-text-base) !important;
		}

		.v-menu__content {
			a {
				color: #333 !important;
			}
		}

		.mobile-icon {
			color: var(--v-header-text-base) !important;
		}
	}

	&.wizard--next {
		background-color: var(--v-header-color-base) !important;
		a {
			color: var(--v-header-text-base) !important;
		}
	}
}

.quick-bar {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 35px;
	background-color: var(--v-header-text-base);
	color: var(--v-header-color-base);

	.phone {
		width: 100% !important;
		color: inherit !important;

		a {
			color: inherit !important;
		}
	}

	.social-icon {
		background-color: var(--v-footer-text-base) !important;
		.v-icon {
			color: var(--v-template-color-darken1);
		}
	}

	.quick-bar-icon {
		color: var(--v-header-color-base);
	}
}

.mobile-quick-bar {
	background-color: var(--v-header-text-base);
	color: var(--v-header-color-base);

	.phone {
		width: 100% !important;
		color: inherit !important;

		a {
			color: inherit !important;
		}
	}
}
</style>
