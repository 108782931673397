<template>
	<div id="work-hours">
		<v-layout wrap justify-center align-center class="text-center pa-5">
			<v-flex xs12 sm12 md12 class="text-h4 font-weight-bold contact-title py-5" v-if="getPageName == 'contact'">Çalışma Saatleri</v-flex>
			<v-flex xs12 sm12 md12 class="text-h4 font-weight-bold contact-title" v-else>Çalışma Saatleri</v-flex>
			<v-flex md12 class="d-flex align-center justify-center text-left py-5">
				<div class="contact" v-if="getPageName == 'contact'">
					<table>
						<tbody>
							<tr v-for="(item, index) in getSite.workHours" :key="index">
								<th scope="row">{{ item.day  }}</th>  
								<td class="text-right">{{ item.hours }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<v-flex md5 v-else>
					<table>
						<tbody>
							<tr v-for="(item, index) in getSite.workHours" :key="index">
								<th scope="row">{{ item.day  }}</th>  
								<td class="text-right">{{ item.hours }}</td>
							</tr>
						</tbody>
					</table>
				</v-flex>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'WorkHours',
	data() {
		return {

		};
	},
	props: {
      page: String,
    },
	computed: {
		...mapGetters(['getSite']),
		getPageName() {
      		return this.page.trim().toLowerCase()
    	}
	},
};
</script>

<style lang="scss" scoped>
#work-hours {
	table {
		width: 100%;
		border-spacing: inherit;
	}
	td, th { 
		border: 1px solid #e3e3e3;
		padding: 1em;
	}
	tr:first-child th:first-child { 
		border-top-left-radius: 10px; 
	}
	tr:first-child td:last-child { 
		border-top-right-radius: 10px; 
	}
	tr:last-child th:first-child { 
		border-bottom-left-radius: 10px; 
	}
	tr:last-child td:last-child { 
		border-bottom-right-radius: 10px; 
	}
	.contact {
		width: 100%;
	}
}
</style>